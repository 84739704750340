<template>
  <div class="chat-wrap" safe-area-inset-top>
    <div class="main">
      <div
        v-for="(item, key) in list"
        :key="key"
        :class="`${item.pusher}-wrap`"
      >
        <div class="img">
          <img :src="item.pusher === 'boot' ? bootImg : userImg" />
        </div>
        <div class="chat-content">
          <img v-if="!item.content" :src="loadingImg" />
          <template v-else>{{ item.content }}</template>
        </div>
      </div>
    </div>
    <div class="footer van-hairline--top" safe-area-inset-bottom>
      <div class="footer-box">
        <van-field v-model="keyword" label="" placeholder="" />
        <van-button type="primary" class="send" @click="handleClick"
          >发送</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Configuration, OpenAIApi } from "openai";
import { remark } from "remark";
import stripMarkdown from "strip-markdown";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      keyword: "",
      list: [],
      freeTimes: 300000,
      isBuy: false,
      loadingImg:
        "https://img.zcool.cn/community/0135eb5a113d80a80121985c9ab171.gif",
      bootImg:
        "https://img1.baidu.com/it/u=3927349831,3775938100&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=501",
      userImg:
        "https://img1.baidu.com/it/u=3927349831,3775938100&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=501",
      clearLocalStorage: "#clearabc#",
    };
  },
  created() {
    let configuration = new Configuration({
      apiKey: "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",
    });
    this.openai = new OpenAIApi(configuration);
  },
  methods: {
    async handleClick() {
      if (this.keyword.trim() !== "") {
        const freeTimesUse = localStorage.getItem("freeTimesUse") || 0;
        if (!this.isBuy && freeTimesUse >= this.freeTimes) {
          this.$toast("免费次数已用完,如需继续使用请购买");
          return false;
        }
        this.list.push({
          pusher: "user",
          content: this.keyword.trim(),
        });
        this.list.push({
          pusher: "boot",
          content: "",
        });

        const response = await this.openai.createCompletion({
          model: "text-davinci-003",
          prompt: this.keyword,
          temperature: 0.9, // 每次返回的答案的相似度0-1（0：每次都一样，1：每次都不一样）
          max_tokens: 4000,
          top_p: 1,
          frequency_penalty: 0.0,
          presence_penalty: 0.6,
          stop: [" Human:", " AI:"],
        });
        localStorage.setItem("freeTimesUse", parseInt(freeTimesUse) + 1);
        this.list[this.list.length - 1].content = response.data.choices[0].text;
        this.keyword = "";
        // setTimeout(() => {
        //   localStorage.setItem("freeTimesUse", parseInt(freeTimesUse) + 1);
        //   this.list[this.list.length - 1].content =
        //     "这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容这是一段内容,非常长的回复内容";
        // }, 3000);
      }
    },
    markdownToText(markdown) {
      return remark()
        .use(stripMarkdown)
        .processSync(markdown ?? "")
        .toString();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.chat-wrap {
  position: relative;
}
.main {
  margin: 10px;
  margin-bottom: 80px;
}
.boot-wrap {
  margin-bottom: 10px;
  display: flex;
  text-align: left;
}
.user-wrap {
  margin-bottom: 10px;
  display: flex;
  // justify-content: flex-end;
  flex-direction: row-reverse;
  text-align: left;
}
.img {
  margin-right: 5px;
  width: 50px;
  img {
    width: 50px;
    border-radius: 5px;
  }
}

.chat-content {
  padding: 10px;
  max-width: 240px;
  line-height: 12px;
  border-radius: 5px;
  background-color: #fff;
  img {
    width: 100px;
  }
}
.user-wrap {
  img {
    margin-left: 5px;
    margin-right: 0px;
  }
  .chat-content {
    background-color: #05dc6c;
  }
}
.footer {
  position: fixed;

  bottom: 0;

  width: 100%;
  background-color: #f7f8fa;
  .footer-box {
    display: flex;
    padding: 10px;
  }
}
.send {
  margin-left: 10px;
  width: 80px;
}
</style>
